import {getCurrencySymbol, getInputValue, getLanguage, getLocale, priceFormat} from '@/utility/helper';
import {INPUT_IDS} from "@/modules/meilisearch/meilisearch.core";
import {typeFiltersLabel, bedBathFiltersLabel} from "@/pages/home/helpers";
import {clearLabelPrice} from "@/pages/search/helpers";

type typeFiltersQueryString = { types?: string, max_price?: number, min_price?: number, bathrooms?: number }
export default () => ({
  isForSale: true,
  open: false,
  tenure: 'sale',
  maxPrice: 0,
  minPrice: 0,
  bathrooms: 0,
  bedrooms: 0,
  types: [],
  showWarning: false,
  switchTenure(isForSale: boolean) {
    this.isForSale = isForSale;
    this.tenure = isForSale ? 'sale' : 'rent';
    if (this.minPrice > 0 || this.maxPrice > 0) {
      this.minPrice = 0;
      this.maxPrice = 0;
      clearLabelPrice();
    }
  },
  submitSearchButton() {
    const locale = getLocale()
    let searchUrl = `/${locale}/for-${this.tenure}`;
    let filtersQueryString = {} as typeFiltersQueryString;
    let location = getInputValue(INPUT_IDS.SEARCH_BOX+'_'+INPUT_IDS.FILTER_LOCATION);

    /* Has filter location */
    if (location) {
      searchUrl += `/${location}`;
    }

    /* Has filter types */
    if (this.types.length > 0) {
      if (this.types.length === 1) {
        searchUrl += `/${this.types[0]}`;
      } else {
        filtersQueryString.types = this.types.join(',');
      }
    }
    /* Has filter beds */
    if (this.bedrooms > 0) {
      searchUrl += `/${this.bedrooms}-bed`;
    }

    if (this.bathrooms > 0) {
      filtersQueryString.bathrooms = this.bathrooms;
    }
    if (this.maxPrice) {
      filtersQueryString.max_price = this.maxPrice;
    }
    if (this.minPrice) {
      filtersQueryString.min_price = this.minPrice;
    }

    let queryString = Object.keys(filtersQueryString)
      .map(key => key + '=' + filtersQueryString[key]).join('&');

    if (queryString) {
      searchUrl += `?${queryString}`;
    }

    //console.log(searchUrl)
    window.location.href = searchUrl;
  },
  validateMinPrice() {
    if (this.minPrice > this.maxPrice && this.maxPrice != 0) {
      this.showWarning = true;
      return;
    }
    this.showWarning = false;
  },
  validateMaxPrice() {
    if (this.maxPrice < this.minPrice && this.maxPrice != 0) {
      this.showWarning = true;
      return;
    }
    this.showWarning = false;
  },
  updateLabel(labelName: string, defaultValue: string) {
    let label = defaultValue;
    let lang = getLanguage();
    const currency = getCurrencySymbol();

    if (labelName === "type") {
      if (this.types.length > 0) {
        // types uppercase first letter and join with comma
        let types = this.types.map((type: string) => {
          let translate = typeFiltersLabel[lang][type] ?? type;
          return translate.charAt(0).toUpperCase() + translate.slice(1)
        });
        label = types.join(', ');
      }
    } else if (labelName === "price") {
      if (this.minPrice > 0) {
        label = `${currency} ${priceFormat(this.minPrice)}`;
      }
      if (this.maxPrice > 0) {
        label += ` - ${currency} ${priceFormat(this.maxPrice)}`;
      }
    } else if (labelName === "bedBaths") {
      if (this.bedrooms > 0) {
        label = `${this.bedrooms}+${bedBathFiltersLabel[lang].beds}`;
      }
      if (this.bathrooms > 0) {
        label += `,${this.bathrooms}+${bedBathFiltersLabel[lang].baths}`;
      }
    }

    return label;
  }
});





